<template>
  <div id="Viplist">
    <div class="Viplist_top">
      <div class="Viplist_top_left">
        <div>会员总数</div>
        <div>{{ memberData && memberData.user_number }}</div>
      </div>
      <div class="Viplist_top_right">
        <div>余额合计</div>
        <div>￥{{ memberData && memberData.wallet }} <span>
            (本金: {{ memberData && memberData.principal }} 赠送：{{ memberData && memberData.tour_money }})</span></div>
      </div>
    </div>
    <div class="Viplist_Table_container">
      <!-- <div class="Viplist_Table_Screen">
        <div class="Viplist_Table_Screen_left">
          <el-button type="primary" size="small">会员导入</el-button>
          <el-button type="info" size="small">修改记录</el-button>
        </div>
        <div class="Viplist_Table_Screen_right">
          <el-select v-model="form.region" placeholder="来源" style="width: 100px;" size="small">
            <el-option label="普通会员" value="shanghai"></el-option>
            <el-option label="黄金会员" value="beijing"></el-option>
          </el-select>
          <el-select v-model="form.region" placeholder="按会员余额有多到少排序" style="width: 200px;" size="small">
            <el-option label="普通会员" value="shanghai"></el-option>
            <el-option label="黄金会员" value="beijing"></el-option>
          </el-select>
          <el-select v-model="screen_Level" placeholder="会员等级" style="width: 100px;" size="small">
            <el-option label="普通会员" value="1"></el-option>
            <el-option label="付费会员" value="2"></el-option>
            <el-option label="游客" value="0"></el-option>
          </el-select>
          <el-select v-model="screen_Status" placeholder="积分状态" style="width: 100px;" size="small">
            <el-option label="正常" :value="1"></el-option>
            <el-option label="冻结" :value="0"></el-option>
          </el-select>
          <el-input v-model="screen_Search" @change="change_Screen" style="width: 150px;" placeholder="请输入身份证后四位/姓名"
            size="small"></el-input>
          <el-button type="text" size="small">重置筛选</el-button>
        </div>
      </div> -->
      <div class="Viplist_Table" :style="{ 'height': Viplist_Table_height + 'px' }">
        <el-table :data="tableData" :style="{ 'width': ' 100%', 'height': maxTable_height + 'px' }"
          :max-height="maxTable_height">
          <el-table-column prop="nickname" label="姓名" align="center"> </el-table-column>
          <el-table-column prop="id_card" label="身份证" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.id_card ? scope.row.id_card : '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.phone ? scope.row.phone : '--' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="grade_name" label="会员等级状态" align="center"> </el-table-column>
          <el-table-column prop="grade_name" label="会员等级" align="center"> </el-table-column>
          <el-table-column prop="wallet" label="余额" align="center"> </el-table-column>
          <el-table-column prop="integral" label="剩余积分" align="center"> </el-table-column>
          <el-table-column prop="total_integral" label="累计积分" align="center"> </el-table-column>
          <el-table-column prop="total_wallet" label="累计充值" align="center"> </el-table-column>
          <el-table-column prop="integral_status_text" label="积分状态" align="center">
            <template slot-scope="scope">
              <span :style="{ 'color': scope.row.integral_status_text == '冻结' ? 'red' : '' }">
                {{ scope.row.integral_status_text }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="注册时间" align="center"> </el-table-column>
          <el-table-column label="操作" width="120" align="right">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="handleEdit(scope.$index, scope.row)">查看</el-button>
              <el-button v-if="scope.row.integral_status_text == '正常'" size="mini" type="text" style="color: #E99B03;"
                @click="handleFreeze(scope.$index, scope.row)">冻结积分</el-button>
              <el-button v-if="scope.row.integral_status_text == '冻结'" size="mini" type="text" style="color: red;"
                @click="changeStatus(scope.row.id)">解除冻结</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <pagination :data="pageNation_data" @size-change="handleSizeChange" @current-change="handleCurrentChange">
          </pagination>
        </div>
      </div>
    </div>


    <el-dialog title="查看" :visible.sync="dialogFormVisible" width="550px">
      <el-form :model="form" label-width="70px">
        <div class="formDisPlay_one">
          <el-form-item label="姓名">
            <el-input v-model="userDetail.nickname" :disabled="true" placeholder="请输入姓名" size="small"></el-input>
          </el-form-item>
          <el-form-item label="剩余积分">
            <el-input v-model="userDetail.integral" :disabled="true" size="small"></el-input>
          </el-form-item>
          <el-form-item label="身份证">
            <el-input v-model="userDetail.id_card" :disabled="true" size="small"></el-input>
          </el-form-item>
          <el-form-item label="累计积分">
            <el-input v-model="userDetail.total_integral" :disabled="true" size="small"></el-input>
          </el-form-item>
          <el-form-item label="会员卡号">
            <el-input v-model="userDetail.id_card" :disabled="true" size="small"></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="userDetail.phone" :disabled="true" size="small"></el-input>
          </el-form-item>
        </div>
        <el-form-item label="会员等级">
          <el-select v-model="userDetail.grade_name" :disabled="true" style="width: 100px;" size="small" placeholder="会员等级">
            <el-option label="普通会员" value="shanghai"></el-option>
            <el-option label="黄金会员" value="beijing"></el-option>
          </el-select>
        </el-form-item>
        <div class="formDisPlay_two">
          <el-form-item label="余额">
            <el-input v-model="userDetail.wallet" :disabled="true" size="small"></el-input>
          </el-form-item>
          <!-- <el-form-item label="本金">
            <el-input v-model="form.name" :disabled="true" size="small"></el-input>
          </el-form-item>
          <el-form-item label="赠送">
            <el-input v-model="form.name" :disabled="true" size="small"></el-input>
          </el-form-item> -->
        </div>
        <el-form-item label="积分状态">
          <el-input v-model="userDetail.integral_status_text" :disabled="true" style="width: 100px;" size="small"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="userDetail.remarks" :disabled="true" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogFormVisible = false" size="small">取 消</el-button> -->
        <el-button type="primary" @click="dialogFormVisible = false" size="small">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import Pagination from '../../../components/PaginationPage/Pagination.vue'
export default {
  components: {
    Pagination
  },
  data() {
    return {
      dialogFormVisible: false,//修改对话框
      memberData: null,//会员数据
      userDetail: {},//用户详情
      tableData: [],//table表格基础数据
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      screen_Level: null,//筛选会员等级
      screen_Search: null,//筛选关键字
      screen_Status: null,//筛选状态

      Viplist_Table_height: window.innerHeight - 235,
      maxTable_height: window.innerHeight - 285,
      // 传递分页组件的数据
      pageNation_data: {
        total: 0,
        currentPage: 1,
        currentSize: 10,
        small: true
      },
    }
  },
  methods: {
    // 监听窗口大小变化
    handleResize() {
      // 在这里处理窗口大小变化的逻辑
      this.Viplist_Table_height = window.innerHeight - 235
      this.maxTable_height = window.innerHeight - 285
    },
    // 分页组件事件
    handleSizeChange(val) {
      // 处理每页数量改变的逻辑
      console.log(`每页 ${val} 条`);
      this.pageNation_data.currentSize = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      // 处理当前页码改变的逻辑
      console.log(`当前页: ${val}`);
      this.pageNation_data.currentPage = val
      this.getUserList()
    },

    /**
      * 冻结积分
      * @param {*} index 
      * @param {*} row 
      */
    handleFreeze(index, row) {
      this.$confirm('冻结积分后，会员将不能获取积分和使用积分, 确定冻结?', '冻结积分', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(() => {
        this.changeStatus(row.id)
        // this.$message.success('冻结成功');
      }).catch(() => {
        this.$message.info('取消冻结');
      });
    },
    /**
     * 查看
     * @param {*} index 
     * @param {*} row 
     */
    handleEdit(index, row) {
      setTimeout(() => {
        this.getUserDetail(row.id)
        this.dialogFormVisible = true
      }, 300);
    },
    // 冻结账号积分
    changeStatus(id) {
      let data = {
        id,
      }
      this.my_request('admin/user/integral-status', data, 'PUT').then(res => {
        console.log('修改积分状态结果:', res)
        let { code, message } = res.data
        if (200 == code) {
          this.$message.success(message)
          this.getUserList()
        } else {
          this.$message.error(message)
        }
      })
    },
    // 获取用户详情
    getUserDetail(id) {
      let data = {
        id,
      }
      this.my_request('admin/user/show', data, 'GET').then(res => {
        console.log('获取用户详情结果', res);
        let { code, data } = res.data
        if (200 == code) {
          this.userDetail = data
        }
      })
    },
    // 获取用户列表
    getUserList() {
      let data = {
        // search: this.screen_Search,
        // phone: null,
        // vip: null,
        // facilitator_id: null,
        // status: null,
        // status: null,
        page: this.pageNation_data.currentPage,
        page_rows: this.pageNation_data.currentSize,
      }
      console.log('获取用户列表data', data);
      this.my_request('admin/user', data, 'GET').then(res => {
        console.log('获取用户列表结果', res);
        let { code, data } = res.data
        if (200 == code) {
          this.tableData = data.list
          this.pageNation_data.total = data.total
        }
      })
    },
    change_Screen() {
      this.getUserList()
    },
    // 获取会员数据
    getMemberData() {
      this.my_request('admin/user/data').then(res => {
        console.log('获取会员数据结果', res);
        let { code, data } = res.data
        if (200 == code) {
          this.memberData = data
        }
      })
    }
  },
  created() {
    this.getUserList()
    this.getMemberData()
  },
  mounted() {
    // 监听窗口大小变化
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    // 移除窗口大小变化的监听
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog__body {
    padding-top: 10px;
  }

  .el-input.el-input--small.is-disabled {
    .el-input__inner {
      color: #000 !important;
    }
  }
}

.el-form {
  .el-form-item {
    margin-bottom: 10px !important;
  }

  .formDisPlay_one {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .el-form-item {
      width: 50%;
    }

    .el-input {
      width: 150px;
    }
  }

  .formDisPlay_two {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .el-input {
      width: 100px;
    }
  }
}

#Viplist {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;

  .Viplist_Table_container {
    margin-top: 10px;

    .pagination {
      margin-top: 20px;
    }

    .Viplist_Table {
      // background-color: aquamarine;
      margin-top: 10px;
    }

    .Viplist_Table_Screen {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;

      .Viplist_Table_Screen_left {
        width: 200px;
      }

      .Viplist_Table_Screen_right {
        display: flex;

        .el-select,
        .el-input {
          margin-right: 10px;
        }
      }
    }
  }

  .Viplist_top {
    height: 95px;
    display: flex;
    justify-content: space-between;

    .Viplist_top_left {
      width: 180px;
      padding: 10px 25px;
      box-shadow: 0 0 10px 0 #dcd9d9;
      border-radius: 10px;
    }

    .Viplist_top_right {
      width: calc(100% - 300px);
      padding: 10px 25px;
      box-shadow: 0 0 10px 0 #dcd9d9;
      border-radius: 10px;

      div:nth-child(2) {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .Viplist_top_right,
    .Viplist_top_left {
      div:nth-child(1) {
        line-height: 20px;
        font-size: 14px;
        font-weight: bold;
      }

      div:nth-child(2) {
        font-size: 30px;
        padding: 10px 0;
      }
    }
  }
}
</style>
